import React, { Component } from 'react';
import './App.css';
import firebase from 'firebase';
import 'firebase/firestore'

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      firestore: {}
    }
  }
  postToDB(firestore, event) {
    console.log("clicked")
    firestore.collection("users").add({
        first: "Ada",
        last: "Lovelace",
        born: 1815
    })
    .then(function(docRef) {
        console.log("Document written with ID: ", docRef.id);
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });
  }
  componentDidMount() {
       fetch('/__/firebase/init.json')
      .then(response => response.json())
      .then(data => firebase.initializeApp(data))
      .then(app => {
        let db = app.firestore()
        this.setState({
          firestore: db
        })
      })
  }
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <button onClick={(e) => this.postToDB(this.state.firestore, e)}>
            Button
          </button>
        </header>
      </div>
    );
  }
}

export default App;
